import React, {useState, useEffect} from 'react';
import './ForgotPassword.scss';

import {forgotPassword} from '@repository/UserRepository';
import {navigate} from 'gatsby';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const handleForgotPassword = () => {
    setIsLoading(true);
    setError('');
    forgotPassword(email)
      .then((res) => {
        setIsLoading(false);
        navigate('/reset-link-sent');
      })
      .catch((err) => {
        if (err.response.data.status_code === 422) {
          setError(err.response.data.errors.email[0]);
        } else {
          console.log(err.response);
        }
        setIsLoading(false);
      })
  };

  return (
    <div id="forgot-password" className="container py-5">
      <div className="row justify-content-center">
        <div className="col-6 d-flex flex-column">
          <div className="dosis-title-1 mx-auto">
            Forgot Password
          </div>
          <div className="divider bg-abalone-gray align-self-center my-4" />
          <div className="asap-body text-coal-black mb-3">
            Enter your e-mail address or username and we'll send you a link to get back into your account.
          </div>
          <div className={`form-group d-flex flex-column-reverse ${error ? 'mb-1' : 'mb-4'} `}>
            <input
              id="email"
              className="form-control"
              placeholder="Enter your username or e-mail address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyPress={event => {
                if (event.key === 'Enter' && !(isLoading || !email)) {
                  handleForgotPassword();
                }
              }}
              required
            />
            <label htmlFor='email' className='input-label'>
              E-mail address
            </label>
          </div>
          {error && (
            <div className="asap-caption-1 text-apple-red mb-4">{error}</div>
          )}
          <button className="button-md button-primary" disabled={isLoading || !email} onClick={handleForgotPassword}>
            {isLoading ? <div className="mdi mdi-loading mdi-spin" /> : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
